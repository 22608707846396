import {
  getAuth,
  getRedirectResult,
  GoogleAuthProvider,
  inMemoryPersistence,
  onAuthStateChanged,
  setPersistence,
  signInWithRedirect,
} from "firebase/auth"
import { getDatabase, ref } from "firebase/database"
import { BrowserRouter } from "react-router-dom"

import React, { useEffect, useState } from "react"
import { Route, Routes } from "react-router"
import { auth } from "."
import "./App.css"
import AuthContext, { Person } from "./AuthContext"
import Comments from "./Components/CommentList/Comments"
import ScussionPage from "./Components/ScussionPage/ScussionPage"
import FirebaseWriter from "./FirebaseWriter/FirebaseWriter"
import Landing from "./Landing"
import App2 from "./App2"

function App() {
  const [person, setPerson] = useState<Person>()

  //Firebase auth
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log((user.displayName ?? "Person") + " has logged in.")
        const personData = {
          displayName: user.displayName ?? undefined,
          uid: user.uid,
          email: user.email ?? undefined,
        }
        setPerson(personData)
      }
    })

    getRedirectResult(getAuth())
      .then((result) => {
        if (result) {
          // This gives you a Google Access Token. You can use it to access Google APIs.
          const credential = GoogleAuthProvider.credentialFromResult(result)
          const token = credential?.accessToken

          // The signed-in user info.
          const user = result.user

          setPerson({
            displayName: user.displayName ?? undefined,
            uid: user.uid,
            email: user.email ?? undefined,
          })
        }
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code
        const errorMessage = error.message
        // The email of the user's account used.
        const email = error.email
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error)
        // ...
      })
  }, [])
  return (
    <BrowserRouter>
      <AuthContext.Provider value={{ person, setPerson }}>
        <Routes>
          <Route
            path={"/"}
            element={<div style={{ color: "white" }}>Ask Davey for the right domain!</div>}
          />
          <Route path={":domainId"} element={<App2 person={person} />} />
        </Routes>
      </AuthContext.Provider>
    </BrowserRouter>
  )
}

export default App
