import React from "react"

export interface Person {
  displayName?: string
  email?: string
  uid: string
}
const AuthContext = React.createContext<{
  setPerson?: (_: any) => void
  person?: Person
}>({})

export default AuthContext
