import { child, DatabaseReference, push, remove, set, update } from "firebase/database"
import { Person } from "../AuthContext"
import { CommentNode } from "../Components/Comment/Comment"

class FirebaseWriter {
  root_ref: DatabaseReference
  auth_id: string
  domain_id: string
  constructor(dbRef: DatabaseReference, auth_id: string, domainId: string) {
    this.root_ref = dbRef
    this.auth_id = auth_id
    this.domain_id = domainId
  }

  addComment(comment: CommentNode, setValue?: Function) {
    const nodesRef = child(this.root_ref, this.domain_id)
    const newCommentRef = push(nodesRef)
    if (newCommentRef.key) {
      comment.id = newCommentRef.key
      return {
        id: newCommentRef.key,
        promise: set(newCommentRef, { ...comment, id: newCommentRef.key }).then(() => {
          //then set for prompts and responses
          //Add all connections
          const { prompts, responses } = comment
          const promptArr = prompts ? Object.keys(prompts) : []
          promptArr.forEach((promptId: string) => {
            set(child(this.root_ref, `${this.domain_id}/${promptId}/responses/${comment.id}`), true)
          })

          const responseArr = responses ? Object.keys(responses) : []
          responseArr?.forEach((responseId: string) => {
            set(child(this.root_ref, `${this.domain_id}/${responseId}/prompts/${comment.id}`), true)
          })
        }),
      }
    } else console.error("key error")
  }

  //assumes both exist
  connectComments(promptId: string, responseId: string) {
    push(child(this.root_ref, `${this.domain_id}/${promptId}/responses`), responseId)
    push(child(this.root_ref, `${this.domain_id}/${responseId}/prompts`), promptId)
  }

  deleteComment(comment: CommentNode) {
    const commentInQuestionRef = child(this.root_ref, `${this.domain_id}/${comment.id}`)
    remove(commentInQuestionRef)
      .then(() => console.log("successfully deleted "))
      .catch((e) => console.error(e))
      //Then delete all locations where it occurred
      .then(() => {
        //Delete all connections
        const promptArr = comment.responses ? Object.keys(comment.responses) : []
        promptArr?.forEach((promptId: string) => {
          remove(child(this.root_ref, `${this.domain_id}/${promptId}/responses/${comment.id}`))
        })
        const responseArr = comment.responses ? Object.keys(comment.responses) : []
        responseArr?.forEach((responseId: string) => {
          remove(child(this.root_ref, `${this.domain_id}/${responseId}/prompts/${comment.id}`))
        })
      })
  }

  ensurePersonExists(personData: Person) {
    let updates: CommentNode = {
      id: personData.uid,
      text: personData.displayName ?? personData.email ?? personData.uid,
      isPerson: true,
      timeStamp: Date.now(),
      author_id: "Plexus",
    }

    update(child(this.root_ref, `${this.domain_id}/${personData.uid}`), updates).catch((e) =>
      console.log(e)
    )
    debugger
  }

  addAttendee(personId: string, commentId: string) {
    update(child(this.root_ref, `${this.domain_id}/${commentId}/attendees`), {
      [personId]: Date.now(),
    })
  }
  deleteAttendee(personId: string, commentId: string) {
    remove(child(this.root_ref, `${this.domain_id}/${commentId}/attendees/${personId}`))
  }
}

export default FirebaseWriter
