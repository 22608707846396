export const color = (uid?: string): string | undefined => {
  return uid
    ? [
        "transparent",
        "rgba(0, 0, 255, .5)",
        "rgba(255, 127, 0, .5)",
        "rgba(255, 255, 0, .5)",
        "rgba(255, 0, 0, .5)",
        "rgba(148, 0, 211, .5)",
        "rgba(75, 0, 130, .5)",
        "rgba(0, 255, 0, .5)",
        "rgba(178, 255,102,  .5)",
        "rgba(102, 178, 255, .5)",
        "rgba(255, 55,251, .5)",
      ][uid.charCodeAt(0) % 6]
    : undefined
}
