import React from "react"
import ReactDOM from "react-dom"
import "./index.css"
import App from "./App"
import reportWebVitals from "./reportWebVitals"

//firebase setup
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app"
import { getAnalytics } from "firebase/analytics"
import {
  browserSessionPersistence,
  getAuth,
  getRedirectResult,
  GoogleAuthProvider,
  inMemoryPersistence,
  setPersistence,
  signInWithRedirect,
} from "firebase/auth"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBMuXrrPlubMf7yDM3mHO2F7hK9H7wqiY8",
  authDomain: "commentgraph.app",
  projectId: "plex-versations",
  storageBucket: "plex-versations.appspot.com",
  messagingSenderId: "118485681483",
  appId: "1:118485681483:web:ee6a9a3fdb5ea88b0fd4ce",
  measurementId: "G-KNHRVEF7JM",
}

// Initialize Firebase
export const app = initializeApp(firebaseConfig)
export const auth = getAuth()

const analytics = getAnalytics(app)
export const provider = new GoogleAuthProvider()

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
