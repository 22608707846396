import React, { useContext, useMemo } from "react"
import CommentsContext from "../../../CommentsContext"
import { CommentNode } from "../../Comment/Comment"
import Comments from "../../CommentList/Comments"
import "./Prompts.css"

const Prompts = ({
  focusedComment,
  promptsSectionOfPage,
}: {
  focusedComment: CommentNode
  promptsSectionOfPage: boolean
}) => {
  //@ts-ignore
  const { commentMap } = useContext(CommentsContext)

  const prompts = useMemo(() => {
    if (!focusedComment || !commentMap) return []
    const promptIds = focusedComment.prompts ? Object.keys(focusedComment.prompts) : []
    const promptObjects = promptIds
      ?.map((id) => commentMap[id])
      .filter((e) => e && e.id !== focusedComment.id)
    return promptObjects ?? []
  }, [commentMap, focusedComment])
  return prompts?.length > 0 ? (
    <div id="prompts" className="comments-container-on-page">
      <div className="section-title">Prompts</div>
      <Comments {...{ commentsToShow: prompts, arePrompts: true, promptsSectionOfPage }} />
    </div>
  ) : (
    <></>
  )
}

export default Prompts
