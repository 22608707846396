import React, { useEffect } from "react"
import { useParams } from "react-router"
import { Person } from "./AuthContext"
import ScussionPage from "./Components/ScussionPage/ScussionPage"
import Landing from "./Landing"

const App2 = ({ person }: { person?: Person }) => {
  return <div className="App">{person ? <ScussionPage /> : <Landing />}</div>
}

export default App2
