import React, { useContext, useMemo } from "react"
import CommentsContext from "../../../CommentsContext"
import { CommentNode } from "../../Comment/Comment"
import Comments from "../../CommentList/Comments"
import "./Responses.css"

const Responses = ({ focusedComment }: { focusedComment: CommentNode }) => {
  const { commentMap } = useContext(CommentsContext)
  const responses = useMemo(() => {
    if (!focusedComment || !commentMap) return []
    const responseIds = focusedComment.responses ? Object.keys(focusedComment.responses) : []
    const responseObjects = responseIds?.map((id) => commentMap[id]).filter((e) => e)
    return responseObjects ?? []
  }, [commentMap, focusedComment])
  return (
    <div className="responses">
      <div className="section-title">Replies</div>
      <div className="comments-container-on-page">
        <Comments {...{ commentsToShow: responses, withAddBox: true }} />
        <div id="buffer-replies" style={{ height: "10vh" }}></div>
      </div>
    </div>
  )
}

export default Responses
