import {
  browserLocalPersistence,
  GoogleAuthProvider,
  setPersistence,
  signInWithRedirect,
} from "firebase/auth"
import React from "react"
import { auth, provider } from ".."
import "./Login.css"

const LoginButton = () => {
  return (
    <div
      className="login-button"
      onClick={() => {
        setPersistence(auth, browserLocalPersistence)
          .then(() => {
            const provider = new GoogleAuthProvider()
            // In memory persistence will be applied to the signed in Google user
            // even though the persistence was set to 'none' and a page redirect
            // occurred.
            return signInWithRedirect(auth, provider)
          })
          .catch((error) => {
            // Handle Errors here.
            const errorCode = error.code
            const errorMessage = error.message
          })
      }}
    >
      log in
    </div>
  )
}

export default LoginButton
