import React, { useContext, useEffect, useMemo, useRef, useState } from "react"
import AuthContext from "../../AuthContext"
import AddComment from "../AddComment/AddComment"
import Comment, { CommentNode } from "../Comment/Comment"
import "./Comments.css"
import { getDatabase, ref, child, get, onValue } from "firebase/database"
import CommentsContext from "../../CommentsContext"

const Comments = ({
  commentsToShow,
  withAddBox,
  arePrompts,
}: {
  commentsToShow: CommentNode[]
  withAddBox?: boolean
  arePrompts?: boolean
}) => {
  const { person } = useContext(AuthContext)
  const commentsEndRef = useRef<any>()

  //@ts-ignore
  const { focusedCommentId } = useContext(CommentsContext)
  const lastChild = useMemo(() => {
    return commentsToShow[commentsToShow.length - 1]
  }, [commentsToShow])
  useEffect(() => {
    //scroll to bottom with every new comment
    commentsEndRef.current?.scrollIntoView({ behavior: "smooth" })
  }, [commentsToShow])
  return (
    <div className="comments" key={`responses-${withAddBox ? "with" : ""}`}>
      {(commentsToShow?.length > 0 ? commentsToShow : []).map((comment, i) => (
        <Comment key={comment.id} comment={comment} isPrompt={arePrompts} {...{ isFocus: false }} />
      ))}
      {withAddBox ? (
        <AddComment
          key={"add comment" + focusedCommentId}
          lastChild={lastChild}
          domainIds={focusedCommentId ? { [focusedCommentId]: true } : {}}
        />
      ) : (
        <></>
      )}
      <div ref={commentsEndRef}></div>
    </div>
  )
}

export default Comments
