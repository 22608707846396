import React, { Dispatch, SetStateAction } from "react"
import { CommentNode } from "./Components/Comment/Comment"

const CommentsContext = React.createContext<{
  comments?: CommentNode[]
  commentMap?: { [key: string]: CommentNode }
  focusedCommentId: string | undefined
  setFocusedCommentId: Function
}>({
  comments: [],
  commentMap: {},
  focusedCommentId: undefined,
  setFocusedCommentId: () => undefined,
})

export default CommentsContext
