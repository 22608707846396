import React, { useContext } from "react"
import CommentsContext from "../../../CommentsContext"
import { CommentNode } from "../Comment"
import Prompt from "./Prompt"
import "./CommentPrompts.css"
import AuthContext from "../../../AuthContext"
import { color } from "../ColorCalculator"
import { filterAttendees } from "../FilterAttendees"

const CommentTags = ({
  comment,
  tagsMap,
  givenMaxChars,
}: {
  comment: CommentNode
  tagsMap: { [key: string]: true }
  givenMaxChars?: number
}) => {
  //@ts-ignore
  const { focusedCommentId } = useContext(CommentsContext)
  const { person } = useContext(AuthContext)
  const { commentMap } = useContext(CommentsContext)

  const tags: string[] = tagsMap
    ? Object.keys(tagsMap)
        .filter((e) => ![focusedCommentId, person?.uid].includes(e))
        .slice(0, 5)
        .sort((a, b) => (commentMap && a in commentMap && commentMap[a].isPerson ? -1 : 1))
    : []
  const maxChars = givenMaxChars ?? 24

  return (
    <div className="comment-prompts">
      {tags.map((tagId, index) =>
        commentMap && tagId in commentMap ? <Prompt key={tagId} {...{ promptId: tagId }} /> : <></>
      )}
    </div>
  )
}

export default CommentTags
