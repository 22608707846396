import React from "react"
import Comment, { CommentNode } from "../../Comment/Comment"
import CommentText from "../../CommentText/CommentText"
import "./FocusedComment.css"

//Won't be completed until reply previews are removed or taken out from bottom of comment
export const FOCUSED_COMMENT_LOOKS_LIKE_COMMENT = true
const FocusedComment = ({ comment }: { comment: CommentNode }) => {
  return FOCUSED_COMMENT_LOOKS_LIKE_COMMENT ? (
    <div style={{ maxWidth: "450pt", width: " 100%", fontSize: "26px", fontWeight: "bold" }}>
      {comment ? <Comment comment={comment} isFocus={true} /> : <></>}
    </div>
  ) : (
    <div className="focused-comment">
      <CommentText isFocus={true} text={comment ? comment.text : "focused comment"} />
      <div style={{ fontSize: "x-small", color: "gray", opacity: 0.5 }}>{comment?.id}</div>
    </div>
  )
}

export default FocusedComment
