import React, { useContext, useMemo } from "react"
import CommentsContext from "../../../CommentsContext"
import { abbreviateSimple } from "../../AddComment/AddComment"
import Prompts from "../../ScussionPage/Prompts/Prompts"
import { color } from "../ColorCalculator"
import { CommentNode } from "../Comment"
import { filterAttendees } from "../FilterAttendees"

const Prompt = ({ promptId }: { promptId: string }) => {
  const { setFocusedCommentId, commentMap } = useContext(CommentsContext)
  const prompt = useMemo(
    () => (commentMap as { [key: string]: CommentNode })[promptId],
    [commentMap, promptId]
  )

  const lastAttendee = useMemo(() => {
    const { attendees } = prompt
    return attendees ? filterAttendees(attendees)[Object.keys(attendees).length - 1] : undefined
  }, [prompt])

  const backgroundColor = color(lastAttendee)
  return (
    <span
      style={{
        //@ts-ignore
        backgroundColor,
        opacity: 0.5,
        color: "white",
      }}
      className="comment-prompt"
      onClick={(e) => {
        e.stopPropagation()
        setFocusedCommentId(prompt.id)
      }}
    >
      {abbreviateSimple(prompt.text)}
    </span>
  )
}

export default Prompt
