import React, { useContext } from "react"
import CommentsContext from "../../../CommentsContext"
import Comments from "../../CommentList/Comments"
import { CommentNode } from "../Comment"
import CommentTags from "./CommentTags"
import "./CommentPrompts.css"

const CommentPrompts = ({
  comment,
  givenMaxChars,
}: {
  comment: CommentNode
  givenMaxChars: number
}) => {
  return comment.prompts ? (
    <CommentTags {...{ comment, tagsMap: comment.prompts, givenMaxChars }} />
  ) : (
    <></>
  )
}

export default CommentPrompts
