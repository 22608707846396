import React, { useContext, useEffect, useMemo, useRef, useState } from "react"
import Comments from "../CommentList/Comments"
import FocusedComment, { FOCUSED_COMMENT_LOOKS_LIKE_COMMENT } from "./FocusedComment/FocusedComment"
import "./ScussionPage.css"
import { getDatabase, ref, child, get, onValue } from "firebase/database"
import { CommentNode } from "../Comment/Comment"
import CommentsContext from "../../CommentsContext"
import Responses from "./Responses/Responses"
import Prompts from "./Prompts/Prompts"
import AuthContext, { Person } from "../../AuthContext"
import Me from "./Me/Name"
//@ts-ignore
import { DropdownMultiple, Dropdown } from "reactjs-dropdown-component"
import { useParams } from "react-router"
import FirebaseWriter from "../../FirebaseWriter/FirebaseWriter"

export let firebaseWriter: FirebaseWriter

const ScussionPage = () => {
  const dbRef = ref(getDatabase())
  const [comments, setComments] = useState<CommentNode[]>([])
  const { person } = useContext(AuthContext)
  const [commentMap, setCommentMap] = useState<{ [key: string]: CommentNode }>({})
  const [focusedCommentId, setFocusedCommentId] = useState<string>("1")
  let previousFocusId = useRef<string>()
  const { domainId } = useParams()
  useEffect(() => {
    //Ensure the person exists later
    //Then, ensure this person exists already
    if (person && domainId) {
      firebaseWriter = new FirebaseWriter(dbRef, person?.uid, domainId)
      firebaseWriter.ensurePersonExists(person as Person)
    }
  }, [domainId])

  useEffect(() => {
    if (person?.uid) {
      if (previousFocusId.current)
        firebaseWriter.deleteAttendee(person?.uid, previousFocusId.current)
      firebaseWriter.addAttendee(person.uid, focusedCommentId)
      previousFocusId.current = focusedCommentId
    }
    const listener = (ev: any) => {
      ev.preventDefault()
      if (person?.uid && previousFocusId.current) {
        firebaseWriter.deleteAttendee(person?.uid, previousFocusId.current)
      }
    }
    window.addEventListener("beforeunload", listener)
    return () => {
      if (person?.uid) {
        firebaseWriter.deleteAttendee(person?.uid, focusedCommentId)
      }
      window.removeEventListener("beforeunload", listener)
    }
  }, [focusedCommentId, Object.keys(commentMap).length])

  useEffect(() => {
    console.log("comment map", commentMap)
  }, [commentMap])
  useEffect(() => {
    onValue(
      child(dbRef, `${domainId}`),
      (snapshot) => {
        console.log("Loading down verses from firebase")

        if (snapshot.exists()) {
          console.log("Verses snapshot exists")
          const val: { [key: string]: CommentNode } = snapshot.val()
          setCommentMap(val)
        } else {
          console.log("No data available")
        }
      },
      (e) => console.error("Firebase comments error: " + e)
    )
    console.log("Setting up firebase value read.")

    //global event handle
    document.addEventListener("keypress", (e) => {
      switch (e.key) {
      }
    })
    document.title = "Comment Graph"
  }, [])

  //set ordered commments based on map
  useEffect(() => {
    if (commentMap && Object.keys(commentMap).length > 0) {
      //Filtered: has an author (so aren't people themselves, sorted by timestamp)
      const valArr = Object.entries(commentMap)
        .filter((arr: any[]) => "author_id" in arr[1])
        .sort((a: any[], b: any[]) => a[1].timestamp - b[1].timestamp)

      const firebaseComments = valArr.map(([key, value]: any[]): CommentNode => {
        const comment = { id: key, ...value } as CommentNode
        return comment
      })
      setComments(firebaseComments)
    }
  }, [commentMap])

  const focusedComment = useMemo(() => {
    return commentMap[focusedCommentId]
  }, [commentMap, focusedCommentId])

  return (
    <CommentsContext.Provider
      value={{ comments, commentMap, focusedCommentId, setFocusedCommentId }}
    >
      <div className="scussion-page">
        {/* <Me /> */}
        {/* Domain dropdown */};{/* <div className="prompts-and-title"> */}
        {FOCUSED_COMMENT_LOOKS_LIKE_COMMENT && false ? (
          <></>
        ) : (
          <Prompts {...{ focusedComment, promptsSectionOfPage: true }} />
        )}
        <FocusedComment {...{ comment: commentMap[focusedCommentId] }} />
        <Responses {...{ focusedComment }} />
      </div>
    </CommentsContext.Provider>
  )
}

export default ScussionPage
