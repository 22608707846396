export const findHashtaggedPrompts = (commentText: string) => {
  //find hashtag indices with regex
  const matches = commentText.match(/#[\S]+/g)
  return matches
}

// export const removePrompts = (commentText: string): string => {
//   let editedText = commentText
//   loop: while(editedText.length > 0){
//     const char = editedText[0]
//     if(char === '#'){

//     }
//   }
// }
