import { child, DatabaseReference, getDatabase, onValue, ref, remove, set } from "firebase/database"
import React from "react"
import { firebaseWriter } from "../../ScussionPage/ScussionPage"
import { CommentNode } from "../Comment"
import "./CommentButtons.css"

const CommentButtons = ({ comment }: { comment: CommentNode }) => {
  return (
    <div className="comment-buttons">
      <div
        onClick={(e) => {
          e.stopPropagation()
          firebaseWriter.deleteComment(comment)
        }}
      >
        delete
      </div>
    </div>
  )
}

export default CommentButtons
