import React, { useContext } from "react"
import CommentsContext from "../../CommentsContext"
import CommentButtons from "./CommentButtons/CommentButtons"
import CommentPrompts from "./CommentTags/CommentPrompts"
import CommentText from "../CommentText/CommentText"
import "./Comment.css"
import CommentTags from "./CommentTags/CommentTags"
import { FOCUSED_COMMENT_LOOKS_LIKE_COMMENT } from "../ScussionPage/FocusedComment/FocusedComment"
import { timeStamp } from "console"
import { auth } from "../.."
import AuthContext from "../../AuthContext"
import { color } from "./ColorCalculator"
import { filterAttendees } from "./FilterAttendees"
export interface CommentNode {
  text: string
  id: string
  timeStamp: number
  author_id: string
  prompts?: { [key: string]: true }
  responses?: { [key: string]: true }
  isPerson?: boolean
  attendees?: { [author_key: string]: number } //timestamp
}
const Comment = ({
  comment,
  isPrompt,
  isFocus,
}: {
  isFocus: boolean
  comment: CommentNode
  isPrompt?: boolean
}) => {
  const { setFocusedCommentId, commentMap } = useContext(CommentsContext)
  const { person } = useContext(AuthContext)

  const maxCharsPerPrompt = 24
  return (
    <>
      <div
        className={
          "comment" +
          (isPrompt ? " prompt" : "") +
          (comment.responses && Object.keys(comment.responses) ? " hasResponses" : "") +
          (isFocus ? " isFocus" : "")
        }
        onClick={() => setFocusedCommentId(comment.id)}
        draggable={true}
      >
        {/* Show prompts inside comments if it isn't the focus or its turned on*/}
        {((FOCUSED_COMMENT_LOOKS_LIKE_COMMENT && false) || !isFocus) && comment.prompts ? (
          <CommentPrompts {...{ comment, givenMaxChars: (isFocus ? 2 : 1) * maxCharsPerPrompt }} />
        ) : (
          <></>
        )}
        <div className={"text-and-buttons" + (isFocus ? " isFocus" : "")}>
          <CommentText text={comment.text} isFocus={isFocus} />
          {commentMap && comment.attendees ? (
            <div style={{ display: "flex" }}>
              {filterAttendees(comment.attendees, person?.uid).map((id) => (
                <div
                  key={id}
                  style={{
                    fontSize: "small",
                    color: "inherit",
                    fontWeight: "bold",
                    borderRadius: "10%",
                    padding: ".5em .3em",
                    margin: ".3em",
                    backgroundColor: color(id),
                  }}
                >
                  {commentMap[id].text.split(" ")[0]}
                </div>
              ))}
            </div>
          ) : (
            <></>
          )}
          {!isFocus && comment.author_id === person?.uid ? (
            <CommentButtons {...{ comment, isFocus }} />
          ) : undefined}
        </div>

        {!isPrompt && !isFocus && comment.responses ? (
          <CommentTags
            {...{
              comment,
              tagsMap: comment.responses,
            }}
          />
        ) : (
          <></>
        )}
      </div>
    </>
  )
}

export default Comment
