import { child, getDatabase, push, ref, set } from "firebase/database"
import React, { useCallback, useContext, useEffect, useRef, useState } from "react"
import { createEditor, Descendant, Editor, Node, Range, Transforms } from "slate"
import { withHistory } from "slate-history"
import { Slate, withReact, Editable, ReactEditor, useSelected, useFocused } from "slate-react"
import { setConstantValue } from "typescript"
import AuthContext from "../../AuthContext"
import CommentsContext from "../../CommentsContext"
import { CommentNode } from "../Comment/Comment"
import "../CommentList/Comments.css"
import { MentionElement } from "../slateTsConfig"
import "./AddComment.css"
import { findHashtaggedPrompts } from "./FindHashtaggedPrompts"
import { hashtagTestStrings } from "./FindHashtaggedPrompts.test"
import ReactDOM from "react-dom"
import { firebaseWriter } from "../ScussionPage/ScussionPage"

const AddComment = ({
  domainIds,
  lastChild,
}: {
  domainIds: { [key: string]: true }
  lastChild?: CommentNode
}) => {
  const { commentMap, setFocusedCommentId } = useContext(CommentsContext)
  const { person } = useContext(AuthContext)

  //For Suggestions
  const ref = useRef<HTMLDivElement | null | undefined | any>()
  const [target, setTarget] = useState<Range | null>()
  const [index, setIndex] = useState(0)
  const [search, setSearch] = useState("")

  const chars =
    commentMap && search
      ? Object.entries(commentMap)
          .map(([key, commentNode], i) => ({ text: commentNode.text, id: key }))
          .filter((c) => c.text?.toLowerCase().includes(search?.toLowerCase()))
          .slice(0, 10)
          .sort((a, b) => a.text.length - b.text.length)
      : []

  //for testing "find hashtagged prompts"

  // useEffect(() => {
  //   const tester = hashtagTestStrings[0]
  //   const matches = findHashtaggedPrompts(tester)

  // }, [])

  const [editor] = useState(() => withMentions(withHistory(withReact(createEditor()))))
  const initialValue: Descendant[] = [
    {
      type: "paragraph",
      children: [{ text: "" }],
    },
  ]
  const [value, setValue] = useState(initialValue)
  useEffect(() => {
    const newvalu = lastChild
      ? [
          {
            type: "paragraph",
            children: [
              { text: "" },
              { type: "mention", character: lastChild.text, children: [{ text: lastChild.text }] },
              { text: "" },
            ],
          },
        ]
      : [
          {
            type: "paragraph",
            children: [{ text: "" }],
          },
        ]
    console.log({ newvalu })

    // if (Node.string(editor).length < 2) {
    //   setValue(initialValue)
    // }
  }, [lastChild])
  const handleEditorChange = (val: Descendant[]) => {
    setValue(val)
    const { selection } = editor

    if (selection && Range.isCollapsed(selection)) {
      const [start] = Range.edges(selection)
      const wordBefore = Editor.before(editor, start, { unit: "word", distance: 1 })
      const before = wordBefore && Editor.before(editor, wordBefore)
      const beforeRange = before && Editor.range(editor, before, start)
      const beforeText = beforeRange && Editor.string(editor, beforeRange)
      const beforeMatch = beforeText && beforeText.match(/^[@|#|/|>](\w+)$/)
      const after = Editor.after(editor, start)
      const afterRange = Editor.range(editor, start, after)
      const afterText = Editor.string(editor, afterRange)
      const afterMatch = afterText.match(/^(\s|$)/)

      if (beforeMatch && afterMatch) {
        setTarget(beforeRange)
        setSearch(beforeMatch[1])
        setIndex(0)
        return
      }
    }

    setTarget(null)
  }
  useEffect(() => {
    if (target && chars.length > 0) {
      const el = ref.current
      const domRange = ReactEditor.toDOMRange(editor, target)
      const rect = domRange.getBoundingClientRect()
      el.style.top = `${rect.top + window.pageYOffset + 24}px`
      el.style.left = `${rect.left + window.pageXOffset}px`
    }
  }, [chars.length, editor, index, search, target])
  const editorRef = useRef<any>()
  const commentSubmit = (e: any) => {
    const commentText = Node.string(editor)
    //parse text for hashtags followed by text, then delete those ones
    //or maybe just for special kind of "prompt" elements
    //what's easiest?
    //hashtags I think

    const withoutPromptHashtags = commentText.replace(/[@|#|/|>][\S]+/g, "")
    const possiblePromptIds = findHashtaggedPrompts(commentText)?.map((e) => e.slice(1))
    const promptIds: { [key: string]: true } =
      possiblePromptIds
        ?.filter((id) => !commentMap || id in commentMap)
        .reduce((obj, nextOne) => ({ ...obj, [nextOne]: true }), {}) ?? {}
    if (person?.uid)
      firebaseWriter
        .addComment({
          text: withoutPromptHashtags,
          author_id: person.uid,
          timeStamp: Date.now(),
          id: "", //will get set inside the firebase function itself
          prompts: { ...domainIds, ...promptIds, [person.uid]: true },
        })
        ?.promise.then(() => setValue(initialValue))

    e.stopPropagation()
    e.preventDefault()
    editor.selection = { anchor: { path: [], offset: 0 }, focus: { path: [], offset: 0 } }
    editor.children = initialValue

    setValue(initialValue)
    ReactEditor.focus(editor)
  }
  // const createNewForInlineSearch = () => {
  //   const id = firebaseWriter.addComment({
  //     author_id: "Davey",
  //     text: search,
  //     id: "undefined",
  //     timeStamp: Date.now(),
  //     prompts: { ["random" + "-" + "Davey"]: true },
  //   })?.id
  //   if (id) insertMention(editor, { text: search, id }, target)
  // }
  const onKeyDown = useCallback(
    (event) => {
      if (target) {
        switch (event.key) {
          case "ArrowDown":
            event.preventDefault()
            const prevIndex = index >= chars.length - 1 ? 0 : index + 1
            setIndex(prevIndex)
            break
          case "ArrowUp":
            event.preventDefault()
            const nextIndex = index <= 0 ? chars.length - 1 : index - 1
            setIndex(nextIndex)
            break
          case "Tab":
          case "Enter":
            event.preventDefault()
            //@ts-ignore
            if (chars.length > 0 && index < chars.length) {
              insertMention(editor, chars[index], target)
            } else {
              // createNewForInlineSearch()
            }
            setTarget(null)
            break
          case "Escape":
            event.preventDefault()
            setTarget(null)
            break
        }
      } else if (["enter", "return"].includes(event.key.toLocaleLowerCase())) {
        commentSubmit(event)
      } else if (["tab"].includes(event.key.toLocaleLowerCase())) {
        //Make bottom of children the title
        // event.preventDefault()
        // if (lastChild) {
        //   setFocusedCommentId(lastChild.id)
        // }
      }
    },
    [index, search, target, lastChild]
  )
  const renderElement = useCallback((props) => <Element {...props} />, [])

  return (
    <div
      className="comment addComment"
      onClick={() => {
        ReactEditor.focus(editor)
      }}
    >
      <Slate editor={editor} value={value} onChange={handleEditorChange}>
        <div className="add-comment">
          <Editable
            renderElement={renderElement}
            autoFocus
            placeholder={"New verse"}
            style={{ width: "100%" }}
            onKeyDown={onKeyDown}
          />
          <div className="submit-comment" onClick={commentSubmit}>
            ➜
          </div>
        </div>
        {target && true && (
          <Portal>
            <div
              ref={ref}
              style={{
                top: "-9999px",
                left: "-9999px",
                position: "absolute",
                zIndex: 1,
                padding: "3px",
                background: "rgba(100,100,100, .4)",
                borderRadius: "4px",
                boxShadow: "0 1px 5px rgba(0,0,0,.2)",
                color: "rgb(210,210,210)",
              }}
              data-cy="mentions-portal"
            >
              {chars.map((char, i) => (
                <div
                  onClick={() => insertMention(editor, char, target)}
                  key={char.id}
                  style={{
                    padding: "1px 3px",
                    borderRadius: "3px",
                    background: i === index ? "rgba(90,90,180, 1)" : "transparent",
                  }}
                >
                  {char.text}
                </div>
              ))}

              {/* <div
                key={"new prompt"}
                style={{
                  padding: "1px 3px",
                  borderRadius: "3px",
                  background: index == chars.length ? "rgba(90,90,180, 1)" : "transparent",
                }}
                onClick={createNewForInlineSearch}
              >
                New "{search}"
              </div>*/}
            </div>
          </Portal>
        )}
      </Slate>
    </div>
  )
}

export default AddComment

const withMentions = (editor: Editor) => {
  const { isInline, isVoid } = editor

  editor.isInline = (element) => {
    return element.type === "mention" ? true : isInline(element)
  }

  editor.isVoid = (element) => {
    return element.type === "mention" ? true : isVoid(element)
  }

  return editor
}
export const abbreviateSimple = (text: string) => {
  const maxChars = 40
  return text.slice(0, 40) + (text.length > maxChars ? "..." : "")
}
const insertMention = (editor: Editor, character: { text: string; id: string }, target: Range) => {
  Transforms.select(editor, target)
  Transforms.insertText(editor, "")
  //Delete the selection

  const mention: MentionElement = {
    type: "mention",
    character: character.text,
    children: [{ text: "#" + character.id }], //+ ' "' + abbreviateSimple(character.text) + '"'
  }
  Transforms.insertNodes(editor, [mention, { text: " " }], { at: Editor.start(editor, []) })
  Transforms.move(editor)
}
export const Portal = ({ children }: { children: any }) => {
  return typeof document === "object" ? ReactDOM.createPortal(children, document.body) : null
}
const Element = (props: any) => {
  const { attributes, children, element } = props
  switch (element.type) {
    case "mention":
      return <Mention {...props} />
    default:
      return <div {...attributes}>{children}</div>
  }
}
const Mention = ({ attributes, children, element }: any) => {
  const selected = useSelected()
  const focused = useFocused()
  return (
    <span
      {...attributes}
      contentEditable={false}
      data-cy={`mention-${element.character.replace(" ", "-")}`}
      style={{
        padding: "3px 3px 3px",
        margin: "0 1px",
        verticalAlign: "baseline",
        display: "inline-block",
        borderRadius: "4px",
        backgroundColor: "rgb(100,100,100, .4)",
        color: "rgb(240,240,240)",
        fontSize: "0.9em",
        boxShadow: selected && focused ? "0 0 0 2px rgb(60,60,200)" : "none",
      }}
    >
      {element.character}
      {children}
    </span>
  )
}
