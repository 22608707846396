import React from "react"
import LoginButton from "./Login/LoginButton"

const Landing = () => {
  return (
    <div className="landing-page">
      <header className="App-header">Comment Graph</header>
      <LoginButton />
    </div>
  )
}

export default Landing
